import React from "react"
import TileList from "../../components/tilelist"
import SubnavList from "../../components/subnavList"

const PageContent = () => (
  <div className="contentArea">
    <h2>Well thought out web and mobile apps</h2>
    <p>Being pretty is a given, but good design isn’t an accident</p>
    <p>
      Explore ideas and apps of all types. Peer into products and the behaviors
      driving them with stories, models, and products that clearly reflect
      research observations and interview results.
    </p>
  </div>
)
const appPage = () => {
  return (
    <div className="projects">
      <SubnavList />
      <TileList />
      <PageContent />
    </div>
  )
}

export default appPage;